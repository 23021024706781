html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #f4f4f4 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.color-inherit, .color-inherit:visited {
  color: inherit;
}

.no-style, .no-style:hover, .no-style:visited, .no-style:visited:hover {
  color: inherit;
  text-decoration: none;
}

.pagination {
  margin-top: 1rem;
}

.pagination nav {
  width: 100%;
}

ul.pagination {
  justify-content: center;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}  